<template>
    <div class='invitation_details zc_dialog_box'>
        <el-dialog :visible.sync="dialogVisible" width="1000px" :close-on-click-modal='false'>
            <div slot="title">
                <div class="name">邀请码明细</div>
            </div>
            <!--  -->
            <div class="details_info scroll_bar_x">
                <el-table :data="tableData" v-loading="loading" :header-cell-style="headerCellStyle" style="width: 100%">
                    <el-table-column prop="" label="序号">
                        <template slot-scope="scope">
                            <div>{{ scope.$index + 1 }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="code" label="邀请码"> </el-table-column>
                    <el-table-column prop="status_text" label="状态">

                    </el-table-column>
                    <el-table-column prop="source_name" label="绑定渠道"> </el-table-column>
                    <el-table-column prop="bind_time" label="绑定时间"> </el-table-column>
                    <el-table-column prop="" label="绑定人(企业名称)" width="140">
                        <template slot-scope="scope">
                            <div v-if="scope.row.member && scope.row.member.memberCorp">{{ scope.row.member.memberCorp.name }}</div>
                            <div v-if="scope.row.member && !scope.row.member.memberCorp">{{ scope.row.member.mobile }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="联系方式">
                        <template slot-scope="scope">
                            <div v-if="scope.row.member">{{ scope.row.member.mobile }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column class-name="one_class" width="90" prop="surplus" label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" v-if="scope.row.status == 1 && $has('562')" @click="changeStatus(2, scope.row.id)">
                                禁用
                            </el-button>
                            <el-button type="text" size="small" v-if="scope.row.status == 2 && $has('562')" @click="changeStatus(1, scope.row.id)">
                                开启
                            </el-button>
                            <el-button type="text" size="small" v-if="$has('560')" @click="delBtn(scope.row.id)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="page_box">

                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
                    @currentChange='currentChangeBtn'></paging-page>
            </div>
        </el-dialog>
        <!-- 提示弹框 -->
        <point-out ref='dialogTip' type='account' @close='accountDelBtn' dialogTitle="温馨提示" dialogWidth='380px'
            :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">确定要删除该条记录吗？</div>
            </div>
        </point-out>
    </div>
</template>

<script>
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import PointOut from '@/components/point_out/point_out.vue'//提示弹框

export default {
    components: { PagingPage, PointOut },
    props: {},
    data() {
        return {
            dialogVisible: false,
            tableData: [],
            headerCellStyle: {
                background: '#F0F2F5',
                color: '#333',
                fontWeight: 500,
                fontSize: '14px'
            },
            limit: 10,
            page: 1,
            total: 0,
            id: '',
            loading: false,
            delId: '',
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn(id) {
            this.dialogVisible = true
            this.id = id
            this.getList()
        },
        sizeChangeBtn(val) {
            console.log(val)
            this.limit = val
            this.page = 1
            this.getList();
        },
        currentChangeBtn(val) {
            console.log(val)
            this.page = val;
            this.getList();
        },
        getList() {
            var params = {
                page: this.page,
                limit: this.limit,
                apply_id: this.id,
            }
            this.loading = true
            // 邀请码明细列表
            this.$personApi.inviteCodeDetails(params).then(res => {
                this.loading = false

                if (res.code == 1000) {
                    this.tableData = res.result.data
                    this.total = res.result.total
                } else {
                    this.$errMsg(res.message)

                }
            })

        },
        // 删除
        delBtn(id) {
            this.delId = id
            this.$refs.dialogTip.openDialogBtn()
        },
        // 删除
        accountDelBtn() {
            this.$personApi.inviteCodeDelete(this.delId).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.getList();

                } else {
                    this.$errMsg(res.message)

                }
            })
        },
        // 更改状态
        changeStatus(status, id) {
            const data = {
                id: id,
                status: status
            }
            this.$personApi.inviteCodeStatus(data).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.getList();
                } else {
                    this.$errMsg(res.message)

                }
            })
        }
    },
}
</script>

<style lang='scss' scoped>
.invitation_details {
    .details_info {
        width: 100%;
        max-height: 500px;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;
        overflow-y: auto;
    }
}

::v-deep .one_class {
    border-left: 1px solid #EBEEF5;
}

.page_box {
    margin-top: 20px;
}
</style>
