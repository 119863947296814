<template>
    <div class="endowments">
        <el-dialog class="zc_dialog_box" :visible.sync="dialogVisible" width="610px" :close-on-click-modal='false'
            append-to-body>
            <div slot="title">
                <div class="name">资质详情</div>
            </div>
            <div class="e_main" v-if="agency_qual">
                <div class="item_title">身份证</div>
                <div class="flex_box">
                    <div class="img">
                        <img :src="agency_qual.idcard_front" alt="">
                    </div>
                    <div class="img">
                        <img :src="agency_qual.idcard_reverse" alt="">
                    </div>
                </div>
                <div class="item_title" v-if="agency_qual.type == 2">营业执照</div>
                <div class="flex_box" v-if="agency_qual.type == 2">
                    <div class="img">
                        <img :src="agency_qual.business_license" alt="">
                    </div>
                </div>
            </div>
            <div slot="footer">
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="openDialogBtn">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: {
        agency_qual: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            dialogVisible: false,
        }
    },
    methods: {
        // 打开弹框
        openDialogBtn() {
            this.dialogVisible = !this.dialogVisible
        },
    }
}
</script>
<style lang="scss" scoped>
.zc_dialog_box {

    ::v-deep .el-dialog {
        .el-dialog__body {
            padding: 30px 50px !important;
        }
    }
    .e_main {
        .item_title {
            color: #333333;
            font-size: 14px;
            margin-bottom: 20px;
        }
    
        .flex_box {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
    
            .img {
                width: 240px;
                height: 152px;
    
                img {
                    width: 100%;
                    height: 100%;
                }
            }
    
        }
    
    }
}

</style>