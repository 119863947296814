<template>
    <div class='organiz_details zc_dialog_box'>
        <el-dialog :visible.sync="dialogVisible" width="1000px" :close-on-click-modal='false'>
            <div slot="title">
                <div class="name">账号明细</div>
            </div>
            <!--  -->
            <div class="details_info scroll_bar_x">
                <el-table :data="tableData" :header-cell-style="headerCellStyle" style="width: 100%">
                    <el-table-column prop="username" label="账号"> </el-table-column>
                    <el-table-column prop="nickname" label="名称"> </el-table-column>
                    <el-table-column prop="memberOrganization.name" label="所属组织"> </el-table-column>
                    <el-table-column prop="staus" label="状态">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="2"
                                @change="corprelatedStatusEdit(scope.row)">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column class-name="one_class" width="90" prop="surplus" label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" v-if="$has('534')" @click="accountBtn(scope.row)">
                                编辑
                            </el-button>
                            <el-button type="text" size="small" v-if="$has('536')" @click="delBtn(scope.row.id)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="page_box">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
                    @currentChange='currentChangeBtn'></paging-page>
            </div>
        </el-dialog>
        <!-- 提示弹框 -->
        <point-out ref='dialogTip' type='account' @close='accountDelBtn' dialogTitle="提示" dialogWidth='380px'
            :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">删除该账号后不能再登录，确定要删除该账号吗？</div>
            </div>
        </point-out>
        <!-- 账号管理 -->
        <account-manage ref="accountManage" :type="2" :orglist='organizationList' :rolelist='roleList'
            @submit="getList"></account-manage>
    </div>
</template>

<script>
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import PointOut from '@/components/point_out/point_out.vue'//提示弹框
import AccountManage from '../account_manage/account_manage.vue'//账号管理

export default {
    components: { PagingPage, PointOut, AccountManage, },
    props: {},
    data() {
        return {
            dialogVisible: false,
            tableData: [],
            headerCellStyle: {
                background: '#F0F2F5',
                color: '#333',
                fontWeight: 500,
                fontSize: '14px'
            },
            limit: 10,
            page: 1,
            total: 0,
            id: '', // 组织id
            loading: false,
            aId: '', // 账号id
            // 组织
            organizationList: [],
            // 角色
            roleList: [],
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn(id) {
            this.dialogVisible = true
            this.id = id
            this.getList()
            // 获取组织/角色列表
            this.listData()
        },
        // 获取组织/角色列表
        listData() {
            var that = this
            that.$personApi.corpRel().then(res => {
                if (res.code == 1000) {
                    // 组织
                    that.organizationList = res.result.organization_tree_dic
                    // 角色
                    that.roleList = res.result.role_dic
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList();
        },
        currentChangeBtn(val) {
            this.page = val;
            this.getList();
        },
        getList() {
            var params = {
                page: this.page,
                limit: this.limit,
                member_org_id: this.id, // 组织id
            }
            this.loading = true
            this.$personApi.corprelatedList(params).then(res => {
                this.loading = false

                if (res.code == 1000) {
                    this.tableData = res.result.data
                    this.total = res.result.total
                } else {
                    this.$errMsg(res.message)

                }
            })
        },
        // 删除
        delBtn(id) {
            this.aId = id
            this.$refs.dialogTip.openDialogBtn()
        },
        // 删除
        accountDelBtn() {
            this.$personApi.corprelatedDelete(this.aId).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.getList();
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 新增和编辑账户
        accountBtn(info) {
            this.$refs.accountManage.openDialogBtn(null, info)
        },
        // 账号状态修改
        corprelatedStatusEdit(info) {
            const data = {
                id: info.id,
                status: info.status
            }
            this.$personApi.corprelatedStatusEdit(data).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.getList()
                } else {
                    this.$errMsg(res.message)

                }
            })
        },
    },
}
</script>

<style lang='scss' scoped>
.organiz_details {
    .details_info {
        width: 100%;
        max-height: 500px;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;
        overflow-y: auto;
    }
}

::v-deep .one_class {
    border-left: 1px solid #EBEEF5;
}

.page_box {
    margin-top: 20px;
}
</style>
