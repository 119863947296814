<template>
  <div class="accountManage">
    <account-info></account-info>
    <div class="con_bottom sec_wrap">
      <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick" v-if="userInfo.is_agency == 0">
        <el-tab-pane label="邀请码" name="oTab1" v-if="$has('56')"></el-tab-pane>
        <el-tab-pane label="账号管理" name="oTab2"
          v-if="userInfo.corp_id > 0 && $has('565')"></el-tab-pane>
        <el-tab-pane label="组织管理" name="oTab3"
          v-if="userInfo.corp_id > 0 && $has('54')"></el-tab-pane>
      </el-tabs>
      <div class="content1 p20" v-if="activeName == 'oTab1' && $has('56')">
        <div class="flex_box">
          <div>
            <el-form ref="params" :model="params" :inline="true" label-position="left" size='small'>
              <el-form-item v-if="$has('561')">
                <el-button class="btnBgColor_blue" type="primary" @click="addCode">新增邀请码</el-button>
              </el-form-item>
              <el-form-item label="分配时间">
                <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
                  end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="search" clearable>
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-button class='btnBgColor_blue_empty_no' type="primary" size="small" :disabled="!ids.length" @click="exportBtn(1, null)">批量导出</el-button>
          </div>
        </div>
        <div class="table_wrap">
          <el-table ref="table1" key="table1" :data="list" v-loading="loading" @sort-change="onSortChange"
            @selection-change="handleSelectionChange" style="width: 100%" max-height="420"
            header-cell-class-name="table-header" cell-class-name="cell-class">
            <template slot="empty">
              <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
            </template>
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="" label="序号">
              <template slot-scope="scope">
                <div>{{ scope.$index + 1 }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作人号码">
              <template slot-scope="scope">
                <div v-if="scope.row.member">{{ scope.row.member.mobile }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="num" label="邀请码数量">
            </el-table-column>
            <el-table-column prop="use_num" label="已使用数量">
            </el-table-column>
            <el-table-column prop="start_date" label="生效时间">
            </el-table-column>
            <el-table-column prop="end_date" label="失效时间">
            </el-table-column>
            <el-table-column prop="create_time" label="分配时间" sortable="custom">
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="120">
              <template slot-scope="scope">
                <el-button type="text" size="small" v-if="$has('558')" @click="codeDetailBtn(scope.row.id)">
                  明细
                </el-button>
                <el-button type="text" size="small" @click="exportBtn(2, scope.row.id)">
                  导出
                </el-button>
                <el-button type="text" size="small" v-if="$has('560')" @click="delBtn1(scope.row.id)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="page_box">
            <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
              @currentChange='currentChangeBtn'></paging-page>
          </div>
        </div>
      </div>
      <div class="content2 p20" v-if="activeName == 'oTab2' && $has('565')">
        <el-form ref="params" :model="params" :inline="true" label-position="left" size='small'>
          <el-form-item v-if="$has('533')">
            <el-button class="btnBgColor_blue" type="primary" icon="el-icon-plus"
              @click="accountBtn(1, {})">新增</el-button>
          </el-form-item>
          <el-form-item label="组织" props="member_org_id">
            <el-select v-model="params.member_org_id" placeholder="请选择组织" @change="search">
              <el-option v-for="item in organizationList" :key="item.value" :label="item.label" :value="item.value"
                :clearable="true">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="角色" props="role_id">
            <el-select v-model="params.role_id" placeholder="请选择角色" @change="search">
              <el-option v-for="item in roleList" :key="item.value" :label="item.label" :value="item.value"
                :clearable="true">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item props="account">
            <el-input placeholder="搜索账号" suffix-icon="el-icon-search" v-model="params.keyword" @change="search"
              :clearable="true">
            </el-input>
          </el-form-item>
        </el-form>
        <div class="table_wrap">
          <el-table ref="table2" key="table2" :data="list" v-loading="loading" style="width: 100%" max-height="420"
            header-cell-class-name="table-header" cell-class-name="cell-class">
            <template slot="empty">
              <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
            </template>
            <el-table-column prop="username" label="账号" width="120">
            </el-table-column>
            <el-table-column prop="" label="角色">
              <template slot-scope="scope">
                <div v-if="scope.row.memberRole">{{ scope.row.memberRole.role_name }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="memberOrganization.name" label="组织" width="120">
            </el-table-column>
            <el-table-column prop="nickname" label="名称" width="120">
            </el-table-column>
            <el-table-column prop="mobile" label="手机号">
            </el-table-column>
            <el-table-column prop="" label="创建人" width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.createMember">{{ scope.row.createMember.mobile }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="create_time" label="创建时间" width="120">
            </el-table-column>
            <el-table-column prop="update_time" label="更新时间" width="110">
            </el-table-column>
            <el-table-column prop="login_time" label="最后登录时间" width="110">
            </el-table-column>
            <el-table-column prop="" label="是否启用" width="120">
              <template slot-scope="scope" v-if="$has('537')">
                <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="2"
                  @change="corprelatedStatusEdit(scope.row)">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="120">
              <template slot-scope="scope">
                <el-button type="text" size="small" v-if="$has('534')" @click="accountBtn(2, scope.row)">
                  编辑
                </el-button>
                <el-button type="text" size="small" v-if="$has('536')" @click="delBtn2(scope.row.id)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="page_box">
            <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
              @currentChange='currentChangeBtn'></paging-page>
          </div>
        </div>
      </div>
      <div class="content3 p20" v-if="activeName == 'oTab3' && $has('54')">
        <el-form ref="params" :model="params" :inline="true" label-position="left" size='small'>
          <el-form-item v-if="$has('543')">
            <el-button class="btnBgColor_blue" type="primary" icon="el-icon-plus"
              @click="organizBtn(1, {})">新增</el-button>
          </el-form-item>
        </el-form>
        <div class="table_wrap">
          <el-table ref="table3" key="table3" class="table3" :data="list" row-key="id" :default-expand-all="false"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" v-loading="loading" style="width: 100%"
            max-height="420" header-cell-class-name="table-header" cell-class-name="cell-class">
            <template slot="empty">
              <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
            </template>
            <el-table-column prop="name" label="组织名称">
            </el-table-column>
            <el-table-column prop="" label="创建人">
              <template slot-scope="scope">
                <div v-if="scope.row.member">{{ scope.row.member.username }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="create_time" label="创建时间">
            </el-table-column>
            <el-table-column prop="" label="更新人">
              <template slot-scope="scope">
                <div v-if="scope.row.updateMember">{{ scope.row.updateMember.username }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="update_time" label="更新时间">
            </el-table-column>
            <el-table-column prop="description" label="描述">
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="180">
              <template slot-scope="scope">
                <el-button type="text" size="small" v-if="$has('544')" @click="organizBtn(2, scope.row)">
                  编辑
                </el-button>
                <el-button type="text" size="small" v-if="$has('565')" @click="organizDetailBtn(scope.row.id)">
                  明细
                </el-button>
                <el-button type="text" size="small" v-if="$has('542')" @click="organizDelBtn(scope.row.id)">
                  删除
                </el-button>
                <el-button type="text" size="small" v-if="$has('543') && scope.row.level == 1"
                  @click="organizBtn(1, scope.row)">
                  新增
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- <div class="page_box">
            <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
              @currentChange='currentChangeBtn'></paging-page>
          </div> -->
        </div>
      </div>
    </div>

    <!-- 邀请码 -->
    <invitation-code ref="invitationCode" @refreshList="getList"></invitation-code>
    <!-- 组织管理 -->
    <organiz-manage ref="organizManage" :type="organiz_type" @refreshList="getList"></organiz-manage>
    <!-- 账号管理 -->
    <account-manage ref="accountManage" :type="account_type" :orglist='organizationList2' :rolelist='roleList'
      @submit="getList"></account-manage>
    <!-- 提示弹框 -->
    <point-out ref='dialogTip1' type='account' @close='codeDelBtn' dialogTitle="提示" dialogWidth='380px' :iconShow='true'
      iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
      <div class="zc_dialog_tip_content">
        <div class="text">确定要删除该条记录吗？</div>
      </div>
    </point-out>
    <!-- 提示弹框 -->
    <point-out ref='dialogTip2' type='account' @close='accountDelBtn' dialogTitle="温馨提示" dialogWidth='380px'
      :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
      <div class="zc_dialog_tip_content">
        <div class="text">删除该账号后不能再登录，确定要删除该成员吗？</div>
      </div>
    </point-out>
    <!-- 邀请码明细 -->
    <invitation-code-detail ref="InvitationCodeDetail"></invitation-code-detail>
    <!-- 组织详情 -->
    <organiz-detail ref="OrganizDetail"></organiz-detail>
    <!-- 删除组织提示弹框 -->
    <point-out ref='organizDel' type='account' @close='organizDelSureBtn' dialogTitle="提示" dialogWidth='380px'
      :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
      <div class="zc_dialog_tip_content">
        <div class="text">删除组织后将永久删除该组织，是否继续？</div>
      </div>
    </point-out>
  </div>
</template>
<script>
import { mapState } from "vuex"

import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import InvitationCode from '../components/invitation_code/invitation_code.vue'//邀请码
import OrganizManage from '../components/organiz_manage/organiz_manage.vue'//组织管理
import AccountManage from '../components/account_manage/account_manage.vue'//账号管理
import PointOut from '@/components/point_out/point_out.vue'//提示弹框
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import AccountInfo from '../components/account_manage/account_info.vue'//账户基本信息
import InvitationCodeDetail from '../components/invitation_code/invitation_code_detail.vue'//账户明细
import OrganizDetail from '../components/organiz_manage/organiz_details.vue'//组织账户明细
export default {
  components: { EmptyData, InvitationCode, OrganizManage, AccountManage, PointOut, PagingPage, AccountInfo, InvitationCodeDetail, OrganizDetail, },
  props: {},
  data() {
    return {
      activeName: '',
      source_list: [],
      params: {
        member_org_id: '',
        role_id: '',
        started: '',
        ended: '',
        keyword: '',
        order_redirect: '',
        order_field: '',
      },
      date: [],
      page: 1,
      limit: 10,
      total: 0,
      loading: false,
      list: [],

      account_type: 2,
      organiz_type: 2,
      codeId: '', // 邀请码id
      organizId: '', // 组织id
      aId: '', // 账号id
      // 组织
      organizationList: [],
      organizationList2: [], // 树形
      // 角色
      roleList: [],
      ids: [],
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo.user_info,
    }),
  },
  watch: {

  },
  created() {
    if (this.$has('56')) {
      this.activeName = 'oTab1'
    } else if (this.$has('565')) {
      this.activeName = 'oTab2'
    } else if (this.$has('54')) {
      this.activeName = 'oTab3'
    }
    this.getList()

  },
  mounted() { },
  methods: {
    // 获取组织/角色列表
    listData() {
      var that = this
      that.$personApi.corpRel().then(res => {
        if (res.code == 1000) {
          // 组织
          that.organizationList = res.result.organization_dic
          that.organizationList2 = res.result.organization_tree_dic
          // 角色
          that.roleList = res.result.role_dic
        } else {
          that.$errMsg(res.message)
        }
      })
    },
    handleClick(tab, event) {
      this.activeName = tab.name
      if (this.activeName == 'oTab2') {
        // 获取组织/角色列表
        this.listData()
      }
      this.resetFrom()
    },
    search() {
      this.page = 1
      this.list = []
      this.total = 0
      this.params.started = this.date.length ? this.date[0] : ''
      this.params.ended = this.date.length ? this.date[1] : ''
      this.getList()
    },
    resetFrom() {
      this.date = []
      this.params.started = ''
      this.params.ended = ''

      this.$refs.params.resetFields()
      this.search()
    },
    sizeChangeBtn(val) {
      this.limit = val
      this.page = 1
      this.getList();
    },
    currentChangeBtn(val) {
      this.page = val;
      this.getList();
    },
    // 列表排序
    onSortChange(column) {
      if (column.order == 'ascending') this.params.order_redirect = 'asc'
      if (column.order == 'descending') this.params.order_redirect = 'desc'
      if (!column.order) this.params.order_redirect = ''
      this.params.order_field = column.prop
      this.search()
    },
    getList() {
      var params = {
        page: this.page,
        limit: this.limit,
        ...this.params
      }
      this.loading = true
      // 邀请码列表
      if (this.activeName == 'oTab1' && this.$has('56')) {
        this.$personApi.inviteApplyList(params).then(res => {
          this.loading = false

          if (res.code == 1000) {
            this.list = res.result.data
            this.total = res.result.total
          } else {
            this.$errMsg(res.message)

          }
        })

      }
      // 账号列表
      if (this.activeName == 'oTab2' && this.$has('565')) {
        this.loading = false

        this.$personApi.corprelatedList(params).then(res => {
          this.loading = false

          if (res.code == 1000) {
            this.list = res.result.data
            this.total = res.result.total
          } else {
            this.$errMsg(res.message)

          }
        })

      }
      // 组织列表
      if (this.activeName == 'oTab3' && this.$has('54')) {
        this.$personApi.organizationList(params).then(res => {
          this.loading = false
          if (res.code == 1000) {
            this.list = res.result
            // this.total = res.result.total
          } else {
            this.$errMsg(res.message)
          }
        })

      }
    },
    // 新增和编辑邀请码
    addCode() {
      this.$refs.invitationCode.openDialogBtn()
    },
    // 新增和编辑账户
    accountBtn(type, info) {
      this.account_type = type

      this.$refs.accountManage.openDialogBtn(null, info)
    },
    // 新增和编辑组织
    organizBtn(type, info) {
      this.organiz_type = type
      let data = {}
      if (type == 1) {
        data = {
          name: '',
          description: '',
          parent_id: info.id,
        }
      }
      if (type == 2) {
        data = {
          name: info.name,
          description: info.description,
          parent_id: info.parent_id,
          id: info.id
        }
      }
      this.$refs.organizManage.openDialogBtn(data)
    },
    // 账号状态修改
    corprelatedStatusEdit(info) {
      const data = {
        id: info.id,
        status: info.status
      }
      if (!this.$has('537')) return
      this.$personApi.corprelatedStatusEdit(data).then(res => {
        if (res.code == 1000) {
          this.$succMsg(res.message)
          this.getList()
        } else {
          this.$errMsg(res.message)

        }
      })
    },
    // 删除
    delBtn1(id) {
      this.codeId = id
      this.$refs.dialogTip1.openDialogBtn()
    },
    delBtn2(id) {
      this.aId = id
      this.$refs.dialogTip2.openDialogBtn()
    },
    // 邀请码删除
    codeDelBtn() {
      this.$personApi.inviteApplyDelete(this.codeId).then(res => {
        if (res.code == 1000) {
          this.$succMsg(res.message)
          this.getList();

        } else {
          this.$errMsg(res.message)

        }
      })
    },
    // 账号删除
    accountDelBtn() {
      this.$personApi.corprelatedDelete(this.aId).then(res => {
        if (res.code == 1000) {
          this.$succMsg(res.message)
          this.getList();
        } else {
          this.$errMsg(res.message)
        }
      })
    },
    // 邀请码明细
    codeDetailBtn(id) {
      this.$refs.InvitationCodeDetail.openDialogBtn(id)
    },
    // 组织详情
    organizDetailBtn(id) {
      this.$refs.OrganizDetail.openDialogBtn(id)
    },
    // 删除组织
    organizDelBtn(id) {
      this.organizId = id
      this.$refs.organizDel.openDialogBtn()
    },
    // 确认删除组织
    organizDelSureBtn() {
      this.$personApi.organizationDelete(this.organizId).then(res => {
        if (res.code == 1000) {
          this.$succMsg(res.message)
          this.getList();
        } else {
          this.$errMsg(res.message)
        }
      })
    },
    // 邀请码 列表勾选
    handleSelectionChange(val) {
      this.ids = val.map(item => item.id)
    },
    // 邀请码 导出 type 1 批量 2 单个
    exportBtn(type, id) {
      var that = this
      var obj = {
        ids: type == 1 ? this.ids.join(',') : id,
      }
      that.$personApi.inviteExport(obj).then(res => {
        return
      })
    },
  },
}
</script>
<style lang='scss' scoped>
@import '../index.scss';

.accountManage {
  min-width: 1700px;

  .con_bottom {
    margin-top: 20px;
  }

  // 将el-table的展开图标替换为其他图标

  ::v-deep .table3 {

    // .cell {
    //   display: flex;
    //   align-items: center;
    //   flex-direction: row-reverse;
    //   justify-content: left;
    // }

    .el-table__expand-icon {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    .el-table__expand-icon .el-icon-arrow-right:before {
      content: "\e791";
    }

    .el-table__expand-icon--expanded .el-icon-arrow-right:before {
      content: "\e790";
    }
  }

}
</style>
