<template>
    <div class="bind_recomend">
        <el-dialog class="dialog1 zc_dialog_box" :visible.sync="dialog1" width="460px" :close-on-click-modal='false'
            append-to-body>
            <div slot="title">
                <div class="name">绑定推荐人</div>
            </div>
            <div class="dialog1_con">
                <div>
                    <el-input v-model="equity_code" placeholder="请输入推荐人的权益码"></el-input>
                </div>
                <div class="tips">提示：每人仅能绑定一个权益码，绑定后无法解绑，请确认操作</div>
                <div>
                    <el-button class="btnBgColor_blue btn" type="primary" @click="bindBtn()">绑定</el-button>
                </div>
                <div class="mt16">
                    <el-button class="btn" @click="applyBtn(1)">不绑定，我要申请成为代理商</el-button>
                </div>

            </div>
        </el-dialog>
        <el-dialog class="dialog2" :visible.sync="dialog2" width="482px" :close-on-click-modal='false' append-to-body>
            <div slot="title">
                <div class="name">请选择您身份</div>
            </div>
            <div class="dialog2_con">
                <div class="item" :class="{ 'active': type == item.type }" v-for="(item, index) in applyList" :key="index"
                    @click="selectID(item)">
                    <div class="active_flag" v-if="type == item.type">
                        <img src="@/assets/images/person/accountManage/bind2.png" alt="">
                    </div>
                    <div class="info">
                        <img :src="item.img" alt="">
                        <div class="txt">{{ item.name }}</div>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <el-button size="small" @click="dialog2Change()">取消</el-button>
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="applyBtn(2)">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog class="dialog3 zc_dialog_box" :visible.sync="dialog3" width="622px" :close-on-click-modal='false'
            append-to-body>
            <div slot="title">
                <div class="name">{{ dialog3Title }}</div>
            </div>
            <div class="dialog3_con">
                <el-form ref="form" :model="form" :rules="rules" label-width="110px">
                    <el-form-item label="手机号码">
                        <el-input class="ipt360" v-model="mobile" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="验证码" prop="sms_code">
                        <el-input class="ipt360" placeholder="请输入验证码" v-model="form.sms_code">
                            <div slot="suffix" class="code_txt" @click="sendMsg()">{{ codeTxt }}</div>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="推荐人" prop="referrer_mobile">
                        <el-input class="ipt360" ref="referrer_mobile" placeholder="请输入推荐人" v-model="form.referrer_mobile"></el-input>
                        <div class="txt">若无推荐人请 <el-link type="primary" :underline="false" @click="referrerChange">点击此处</el-link></div>
                    </el-form-item>
                    <el-form-item label="身份证照片">
                        <div class="flex_box">
                            <upload-img width="200px" height="125px" bg_img="bind3.png" label="上传人像面"
                                :imageUrl="idcard_front" @uploadSuccess="uploadSuccess1"></upload-img>
                            <upload-img width="200px" height="125px" bg_img="bind4.png" label="上传国徽面"
                                :imageUrl="idcard_reverse" @uploadSuccess="uploadSuccess2"></upload-img>
                        </div>
                        <div class="upload_tips flex_box">
                            <div class="tips_txt"><span class="span1">*</span> 仅支持 <span class="span2">jpg、gif、png</span> 格式
                                格式,且文件小于 <span class="span2">2M</span>。</div>
                            <div class="flex_box example">
                                <img src="@/assets/images/person/accountManage/bind8.png" alt="">
                                <img src="@/assets/images/person/accountManage/bind7.png" alt="">
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="营业执照" v-if="type == 2">
                        <div class="flex_box">
                            <upload-img width="200px" height="135px" bg_img="bind5.png" label="上传营业执照"
                                :imageUrl="business_license" @uploadSuccess="uploadSuccess3"></upload-img>
                            <div class="tips_txt"><span class="span1">*</span> 仅支持 <span class="span2">jpg、gif、png</span> 格式
                                格式,且文件小于 <span class="span2">2M</span>。</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="补充说明">
                        <el-input type="textarea" :autosize="{ minRows: 4 }" v-model="form.desc" maxlength="150"
                            show-word-limit></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button size="small" @click="dialog3Change()">取消</el-button>
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="applyBtn(3)">提交</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import UploadImg from '@/components/upload/upload_img.vue'
import { mapState } from "vuex"

export default {
    components: { UploadImg },
    data() {
        return {
            dialog1: false,
            equity_code: '', // 权益码
            dialog2: false,
            applyList: [
                {
                    type: 1,
                    img: require('@/assets/images/person/accountManage/bind9.png'),
                    name: '个人申请'
                },
                {
                    type: 2,
                    img: require('@/assets/images/person/accountManage/bind10.png'),
                    name: '企业申请'
                },
            ],
            type: 1,
            dialog3: false,
            form: {},
            idcard_front: '',
            idcard_reverse: '',
            business_license: '',
            // mobile: '',
            rules: {
                sms_code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    { min: 4, max: 4, message: '长度在 4 个字符', trigger: 'blur' }
                ],
                referrer_mobile: [
                    { required: true, message: '请输入推荐人' },
                ],
            },
            disabled: false,
            codeTxt: '发送验证码',
            codeTime: 60,
            timer: null,
        }
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.userInfo.user_info,
            mobile: (state) => state.userInfo.user_info.mobile,
        }),
        dialog3Title() {
            let str = ''
            if (this.type == 1) {
                str = '代理商资质上传-个人'
            }
            if (this.type == 2) {
                str = '代理商资质上传-企业'

            }
            return str
        }
    },
    methods: {
        openDialogBtn1() {
            this.dialog1 = !this.dialog1
        },
        // 绑定
        bindBtn() {
            if (!this.equity_code) {
                this.$errMsg('请输入权益码')
                return
            }
            this.$personApi.bindParentAgency({ equity_code: this.equity_code }).then(res => {
                this.nameFlag = false
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.$emit('refreshInfo')
                } else {
                    this.$errMsg(res.message)
                }
            })
            this.openDialogBtn1()

        },
        // 申请代理
        applyBtn(val) {
            if (val == 1) {
                this.openDialogBtn1()
                this.dialog2Change()
            }
            if (val == 2) {
                this.dialog2Change()
                this.dialog3Change()

            }
            if (val == 3) {
                this.submitBtn()
            }
        },
        submitBtn() {
            if (this.type == 1) {

                if (!this.idcard_front) {
                    this.$errMsg('请上传身份证图片')
                    return
                }
                if (!this.idcard_reverse) {
                    this.$errMsg('请上传身份证图片')
                    return
                }
            }
            if (this.type == 2) {
                if (!this.business_license) {
                    this.$errMsg('请上传营业执照图片')
                    return
                }
            }
            if (!this.form.referrer_mobile) {
                this.$errMsg('请输入推荐人')
                return
            }

            const data = {
                type: this.type,
                mobile: this.mobile,
                idcard_front: this.idcard_front,
                idcard_reverse: this.idcard_reverse,
                business_license: this.business_license,
                ...this.form,
            }
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    console.log(data)
                    
                    this.$personApi.agencyApply(data).then(res => {
                        if (res.code == 1000) {
                            this.$succMsg(res.message)
                            this.dialog3Change()
        
                        } else {
                            this.$errMsg(res.message)
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        dialog2Change() {
            this.dialog2 = !this.dialog2
        },
        // 选择身份
        selectID(item) {
            this.type = item.type
        },
        dialog3Change() {
            this.dialog3 = !this.dialog3
        },
        // 发送验证码
        sendMsg() {
            var that = this
            if (that.disabled) {
                return
            }
            var params = {
                mobile: this.mobile
            }
            this.$publicApi.sendSms(params).then(res => {
                if (res.code == 1000) {
                    if (!this.timer) {
                        that.disabled = true
                        that.codeTxt = that.codeTime + 's'
                        that.timer = setInterval(() => {
                            if (that.codeTime > 0 && that.codeTime <= 60) {
                                that.codeTime--
                                that.codeTxt = that.codeTime + 's'
                            } else {
                                that.disabled = false
                                that.codeTime = 60
                                that.codeTxt = '重新获取'
                                clearInterval(that.timer)
                                that.timer = null;
                            }
                        }, 1000)
                    }
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 上传身份证正面
        uploadSuccess1(url) {
            this.idcard_front = url
        },
        // 上传身份证反面
        uploadSuccess2(url) {
            this.idcard_reverse = url
        },
        // 上传营业执照
        uploadSuccess3(url) {
            this.business_license = url
        },
        referrerChange() {
            this.$set(this.form, "referrer_mobile", "无")
        }
    }
}
</script>
<style lang="scss" scoped>
.dialog1 {
    ::v-deep .el-dialog {
        .el-dialog__body {
            padding: 30px 50px !important;
        }
    }

    .dialog1_con {
        .tips {
            font-size: 12px;
            color: #9097A5;
            line-height: 17px;
            margin-bottom: 40px;
            margin-top: 12px;
        }

        .btn {
            width: 100%;
        }
    }
}

.dialog2 {
    ::v-deep .el-dialog {
        border-radius: 6px;
        background: url('~@/assets/images/person/accountManage/bind1.png') no-repeat;
        background-size: cover;

        .el-dialog__header {
            padding: 16px 20px;
            //   border-bottom: 1px solid $greyBorderColor;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }

        .el-dialog__body {
            padding: 30px 50px;
        }

        .el-dialog__footer {
            padding: 20px;
        }
    }

    .dialog2_con {
        display: flex;
        justify-content: space-between;

        .item {
            width: 176px;
            height: 200px;
            background: #FFFFFF;
            border-radius: 10px;
            border: 1px solid #E2E2E2;
            position: relative;

            .active_flag {
                position: absolute;
                top: -1px;
                right: -1px;

                img {
                    width: 32px;
                    height: 32px;
                }
            }

            .info {
                position: absolute;
                top: calc(50% - (98px / 2));
                left: calc(50% - (64px / 2));

                img {
                    width: 64px;
                    height: 64px;
                    border-radius: 50%;
                }

                .txt {
                    color: #333333;
                    font-size: 14px;
                    margin-top: 20px;
                }
            }
        }

        .active {
            border: 2px solid #2E4BF2;
        }
    }
}

.dialog3 {
    ::v-deep .el-dialog {
        .el-dialog__body {
            padding: 30px 50px !important;
        }
    }

    .dialog3_con {
        .ipt360 {
            width: 360px;
        }

        .code_txt {
            font-size: 13px;
            color: #FF7432;
            border-left: 1px solid #EFEFEF;
            padding: 0 12px;
            cursor: pointer;
            line-height: 26px;
            height: 26px;
            margin-top: 7px;
        }

        .flex_box {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }


        .tips_txt {
            width: 200px;
            color: #999999;
            font-size: 12px;
            line-height: 16px;

            .span1 {
                color: #FF0F0F;
            }

            .span2 {
                color: #333;
            }
        }

        .upload_tips {
            background: #FAFAFA;
            border-radius: 6px;
            border: 1px dashed #DDDFE6;
            padding: 10px;
            margin-top: 16px;

            .example {
                img {
                    width: 80px;
                    height: 50px;
                    margin-left: 10px;
                }
            }
        }
    }
}

.mt16 {
    margin-top: 16px;
}
</style>